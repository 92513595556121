import React from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import Img from "gatsby-image"
import * as PropTypes from "prop-types"

import style from "./index.module.css";

const propTypes = {
  data: PropTypes.object.isRequired,
}

class Work extends React.Component {
  render() {
    const content = this.props.data.page
    return (
      <div>
        <Helmet>
          <title>{content.title} - Strawberry Earth</title>
          {content.metaDescription &&
            <meta name="description" content={content.metaDescription} />
          }
        </Helmet>
        <div className={style.content}>
          {content.title &&
            <h1 className={style.title}>{content.title}</h1>
          }
        </div>

        <section className={style.projectContainer}>
          <div className={style.list}>
            {content.projects.map(( node, index ) => {
              const itemSize = "Large" === node.size ? style.itemWide : style.item;
              return (
                <article className={itemSize} key={index}>
                  <Link to={`/werk/${node.slug}/`}>
                    <div className={style.projectContent}>
                      <h1 className={style.projectName}>{node.name}</h1>
                      <div className={style.projectSubtitle}>{node.subtitle}</div>
                    </div>
                    <Img className={style.image} sizes={node.imageOverview.sizes} />
                  </Link>
                </article>
              )
            })}
          </div>
        </section>
      </div>
    )
  }
}

Work.propTypes = propTypes

export default Work

export const WorkPageQuery = graphql`
  query WorkPageQuery {
    page: contentfulPageWork {
      title
      metaDescription
      projects {
        id
        name
        slug
        size
        subtitle
        date
        imageOverview {
          sizes(maxWidth: 3500) {
            sizes
            src
            srcSet
            srcWebp
            srcSetWebp
          }
        }
      }
    }
  }
`